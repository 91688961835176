import { ChangeEvent, useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import * as signalR from "@microsoft/signalr";
import { useMsal } from "@azure/msal-react";
import { IconButton } from "@tradesolution/iceberg-ui-react";


interface Props {
    handleFile: (fileUploaded: any) => void
    title?: string | undefined
    isImageUploaded: boolean; 
    uploading: boolean;  //ny prop bilde lastes opp
  }
  
const FileUploaderTestSide = (props: Props) => {
    const hiddenFileInput = useRef<any>(null);
  
    const handleClick = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (hiddenFileInput.current) {
        hiddenFileInput.current.click();
      }
    };
  
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();    
      event.stopPropagation();    
      if (event.target.files) {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
      }
    }
  
    const cameraIconColor = props.uploading ? 'btn btn-primary disabled' : (props.isImageUploaded ? 'btn btn-outline-success' : 'btn btn-primary');
  
    return (
      
      <>
        <IconButton icon="camera" title={props.title ?? 'Last opp bilde med ingredienser'} onClick={e => handleClick(e)} className={cameraIconColor} />
        <input
          type="file"
          onChange={handleChange}
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          accept="image/*"
          capture="environment"
        />
      </>
  
    );
  };

export default FileUploaderTestSide;