import React from 'react';
import ReactDOM from 'react-dom/client';
import './Layout/Layout.scss';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './Shared/Auth/authConfig';
import Layout from './Layout/Layout';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import IngredienserPage from './Pages/Ingredienser';
import TilbakemeldingerPage from './Pages/Tilbakemeldinger';
import SmartPastePage from './Pages/SmartPaste';
import SmartSummaryPage from './Pages/SmartSummary';
import ToastProvider from './Components/Toaster/ToastProvider';
import FeilmerkingPage from './Pages/Feilmerking';
import SignalRTest from './Pages/SignalR';
import FileUploaderTestSide from './Pages/TestSide';


const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ToastProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route index element={<IngredienserPage />} />
              <Route path='tilbakemeldinger' element={<TilbakemeldingerPage />} />
              <Route path='smartpaste' element={<SmartPastePage />} />
              <Route path='smartsummary' element={<SmartSummaryPage />} />
              <Route path='feilmerking' element={<FeilmerkingPage />} />
              <Route path='chat' element={<SignalRTest />} />
              <Route path='testside' element={<FileUploaderTestSide handleFile={function (fileUploaded: any): void {
                throw new Error('Function not implemented.');
              } } isImageUploaded={false} uploading={false} />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ToastProvider>
    </MsalProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
